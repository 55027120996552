@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

:root {
  --essay-text-font-size: 15pt;
}

/* 

Default classes 

*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* 

Layout

*/

/* This is to make the whole page horizontally centered */
body {
  min-height: 100vh;
  display: flex;
  align-items: flex-start; /* Change this to 'center' to make it vertically centered */
  justify-content: space-around;
}

.centered-content {
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex !important;
  justify-content: center !important;
}

.horizontal-content {
  flex-direction: row;
  justify-content: center;
}
@media screen and (min-width: 0px) and (max-width: 640px) {
  .horizontal-content {
    flex-direction: column !important;
    justify-content: center !important;
  }
}

.vertical-content {
  flex-direction: column;
  justify-content: center;
}

/* 

Containers

*/

.container {
  font-family: "Lato", sans-serif;
  background-color: #ffffff;
  max-width: 525pt;
  /* max-width: 95%;
  margin: auto; */
  padding-top: 36pt;
  padding-left: 10pt;
  padding-right: 10pt;
  padding-bottom: 36pt;
}

/* 

Text

*/

.essay-title {
  font-size: calc(var(--essay-text-font-size) + 5pt);
  font-weight: bolder;
  color: black;
  line-height: 1.25em;
  margin-bottom: 0;
}
.essay-date {
  font-size: calc(var(--essay-text-font-size) - 2pt);
  color: #666666;
  font-style: italic;
  margin-bottom: 1.5em;
  margin-top: 0px;
}
.essay-section-heading {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: calc(var(--essay-text-font-size) + 4pt);
}
.essay-subsection-heading {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: calc(var(--essay-text-font-size) + 3pt);
  color: #444444;
}
.essay-subsection-subheading {
  margin-top: -8px;
  margin-bottom: 0px;
  font-size: calc(var(--essay-text-font-size) + 1pt);
  color: #666666;
  font-style: italic;
}
.essay-paragraph {
  margin-top: 0px;
  margin-bottom: 1.2em;
}
.essay-text {
  font-size: var(--essay-text-font-size);
  color: black;
}
.quote-block {
  font-size: var(--essay-text-font-size);
  color: black;
  font-style: italic;
  margin-left: 35px;
  margin-top: 5px;
}

/* 

Lists

*/

.arrow-list {
  list-style-type: none;
  padding-left: 35px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.arrow-list-item {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
}
.arrow-list-arrow {
  margin-right: 10px;
}
.arrow-list-subitem {
  padding-left: 50px;
}

/* 

Images

*/

.image-container {
  display: grid;
  place-items: center;
}
.essay-image {
  max-width: 70%;
  max-height: 325px;
  margin-top: 5px;
  margin-bottom: 0px;
  padding-bottom: 5px;
}
.essay-image-large {
  max-width: 80%;
  max-height: 450px;
}
.image-caption {
  max-width: 70%;
  font-family: "Lato", sans-serif;
  font-size: calc(var(--essay-text-font-size) - 1pt);
  color: #666666;
  font-style: italic;
  margin-bottom: 1.5em;
  margin-top: 0px;
  text-align: center;
}

/*

Mobile sizing

*/
@media screen and (max-width: 640px) {

  .centered-content {
    flex-direction: column !important; /* Stack elements vertically */
  }

  .arrow-list {
    list-style-type: none;
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .arrow-list-subitem {
    padding-left: 25px;
  }

  .essay-image {
    max-width: 100%; /* Ensure images fit within the container */
  }
  .essay-image-large {
    max-width: 100%;
  }
  .image-caption {
    max-width: 95%;
  }
}


/* 

Components

*/
a {
  text-decoration: none;
  height: inherit;
}

.section-header {
  font-size: 0.9em;
  margin-left: 50px;
  margin-right: 50px;
  font-family: "Lato", sans-serif !important;
}

.section-header-text {
  margin-top: 40px;
  margin-bottom: 0px;
  color: #7d6e5d;
}

.essay-card {
  width: 90%;
  max-width: 600px;
  margin: 15px 50px 25px 50px;
  text-align: left;
  font-size: 1.3em;
  font-family: "Lato", sans-serif !important;
  color: #1c1c1c !important;
  background-color: #fbfbfb !important;
  border-radius: 15px !important;
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.01),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}
@media screen and (min-width: 0px) and (max-width: 640px) {
  .essay-card {
    margin: auto;
    margin-top: 15px;
    margin-bottom: 25px;
  }
}
/* These are for removing the shadow on mouse-down and adding the shadow back on mouse-up */
.essay-card:active {
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
}
.essay-card:visited {
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.01),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}

.essay-card-action-area {
  width: 100%;
  height: 100%;
  border-radius: 15px !important;
}

.essay-card-content {
  padding: 15px 30px 5px 30px !important;
}

.essay-card-title {
  margin-top: 10px;
  margin-bottom: 0px;
}

.essay-card-subtitle {
  margin-top: 5px;
  margin-bottom: 15px;
  color: rgb(123, 110, 110);
  font-style: italic;
  font-weight: 400;
}

.essay-card-preview {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 400;
}

.essay-card-footnote {
  margin-top: 0px;
  margin-bottom: 15px;
  color: rgb(123, 110, 110);
  font-style: italic;
  font-weight: 400;
  text-align: center;
}

.info-button {
  font-family: "Lato", sans-serif;
  background: none;
  border: none;
  padding-left: 0px;
  padding-right: 3px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  line-height: 1;
  font-size: calc(var(--essay-text-font-size) - 4pt);
  color: #555555;
}

.info-button-closed {
  color: black;
}

.info-button-open {
  color: #555555;
}

.side-note-text {
  font-size: calc(var(--essay-text-font-size) - 1pt);
}

.google-doc-iframe-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  margin: auto auto auto 20%;
}
@media screen and (min-width: 0px) and (max-width: 480px) {
  .google-doc-iframe-container {
    margin: auto auto auto 0%;
  }
}
@media screen and (min-width: 481px) and (max-width: 1020px) {
  .google-doc-iframe-container {
    margin: auto auto auto 5%;
  }
}
@media screen and (min-width: 1021px) and (max-width: 1140px) {
  .google-doc-iframe-container {
    margin: auto auto auto 10%;
  }
}
@media screen and (min-width: 1141px) and (max-width: 1260px) {
  .google-doc-iframe-container {
    margin: auto auto auto 15%;
  }
}

.google-doc-iframe {
  border: 0px;
}